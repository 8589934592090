
import React from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
const spots = require("../../assets/spots/spots00009.png");
const fullLogoColor = require("../../assets/full_logo_color.png");
const arrowSvg = require("../../assets/svg/arrow.svg");
import { useNavigation } from "@react-navigation/native";

export function BG({ idx }: { idx: number }) {
	return (
		<Image
			onLayout={({ nativeEvent }) => {
				//@ts-expect-error
				const t = nativeEvent?.target as any;
				const img = t?.querySelector("img");
				t.style["mix-blend-mode"] = "overlay";
				img.style["transform"] = "rotate(146deg)";
			}}
			source={{ uri: spots }}
			style={[
				{
					width: "100%",
					height: "33%",
					top: `${idx * 33}%`,
					position: "absolute",
				},
			]}
		/>
	);
}

export function Datenschutz() {
	const s = StyleSheet.create({
		h1: { fontSize: 19, fontWeight: "700", marginTop: 15 },
		h2: { fontSize: 15, fontWeight: "700", marginTop: 13 },
		h3: { fontSize: 13, fontWeight: "600", marginTop: 13 },
		body: { fontSize: 13, lineHeight: 21, marginTop: 13 },
	});

	const nav = useNavigation();

	return;
	<View style={{ backgroundColor: "#E7EAFB", flex: 1 }}>
		<BG idx={0} />
		<BG idx={1} />
		<BG idx={2} />

		<View
			style={{
				flex: 1,
				flexDirection: "row",
				width: "100%",
				justifyContent: "space-between",
				marginTop: 33,
				marginBottom: 15,
			}}
		>
			<TouchableOpacity
				style={[
					{
						left: 23,
						// fix
						//	position: "flex"
					},
				]}
				onPress={() => nav.goBack()}
			>
				<Image
					source={{
						uri: arrowSvg.default, // only for svgs?
						height: 12,
						width: 15,
					}}
					style={{ tintColor: "black" }}
				/>
			</TouchableOpacity>
			<Image source={{ uri: fullLogoColor, height: 44, width: 141 }} style={{ marginTop: 0 }} />
			<Text style={{ width: 15 }}> </Text>
		</View>
		<View style={{ margin: 18 }}>
			<Text
				style={[
					{
						//fontFamily:"Inter",
						fontSize: 20,
						fontWeight: "700",
						lineHeight: 24,
						//letterSpacing: "0em",
						textAlign: "left",
						textTransform: "uppercase",
					},
					{
						//							background: "linear-gradient(90deg, #8425F0 0%, #FF17C0 )",
						//						WebkitTextFillColor: "transparent",
						//					backgroundClip: "text",
					},
				]}
			>
				Datenschutzerklärung
			</Text>
			<Text style={s.h1}>1. Datenschutz auf einen Blick</Text>
			<Text style={s.h2}>Allgemeine Hinweise</Text>
			<Text style={s.body}>
				Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
				personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten
				sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche
				Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
				Datenschutzerklärung.
			</Text>
			<Text style={s.h2}>Datenerfassung auf dieser Website</Text>
			<Text style={s.h3}>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</Text>
			<Text style={s.body}>
				Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
				Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser
				Datenschutzerklärung entnehmen.
			</Text>
			<Text style={s.h3}>Wie erfassen wir Ihre Daten?</Text>
			<Text style={s.body}>
				Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
				sich z. B. um Daten handeln, die Sie beim Registrieren eingeben. Andere Daten werden
				automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT- Systeme
				erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
				Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
				Website betreten.
			</Text>
			<Text style={s.h3}>Wofür nutzen wir Ihre Daten?</Text>
			<Text style={s.body}>
				Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
				gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
			</Text>
			<Text style={s.h3}>Welche Rechte haben Sie bezüglich Ihrer Daten?</Text>
			<Text style={s.body}>
				Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck
				Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
				Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
				Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft
				widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
				Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
				Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum
				Thema Datenschutz können Sie sich jederzeit an uns wenden.
			</Text>
			<Text style={s.h3}>Analyse-Tools und Tools von Drittanbietern</Text>
			<Text style={s.body}>
				Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das
				geschieht vor allem mit sogenannten Analyseprogrammen. Detaillierte Informationen zu diesen
				Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
			</Text>
			<Text style={s.h1}>2. Datenschutz auf einen Blick</Text>
			<Text style={s.h2}>Externes Hosting </Text>
			<Text style={s.body}>
				Diese Website wird bei einem externen Dienstleister gehostet (easyname). Die
				personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des
				Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und
				Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
				die über eine Website generiert werden, handeln.
			</Text>
			<Text style={s.body}>
				Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren
				potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer
				sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen
				professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
			</Text>
			<Text style={s.body}>
				Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
				ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit
				die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
				des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist
				jederzeit widerrufbar. Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
				Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese
				Daten befolgen. Wir setzen folgenden Hoster ein:
			</Text>
			<Text style={s.body}>
				easyname GmbH{"\n"}
				Canettistraße 5/10{"\n"}
				A-1100 Wien{"\n"}
			</Text>
			<Text style={s.h2}>Auftragsverarbeitung</Text>
			<Text style={s.body}>
				Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter
				geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
				der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach
				unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
			</Text>
			<Text style={s.h1}>3. Allgemeine Hinweise und Pflichtinformationen </Text>
			<Text style={s.h2}>Datenschutz</Text>
			<Text style={s.body}>
				Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
				behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen
				Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website benutzen,
				werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit
				denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
				erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
				welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet
				(z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
				Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
			</Text>
			<Text style={s.h2}>Hinweis zur verantwortlichen Stelle</Text>
			<Text style={s.body}>
				Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
			</Text>
			<Text style={s.body}>
				Finstep Solutions GmbH{"\n"}
				Fuhlsbüttler Straße 29 22305 Hamburg{"\n"}
				Telefon: 016097929150{"\n"}
				E-Mail: admin@finstep.de
			</Text>
			<Text style={s.body}>
				Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam
				mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B.
				Namen, E-Mail-Adressen o. Ä.) entscheidet.
			</Text>
			<Text style={s.h2}>Speicherdauer</Text>
			<Text style={s.body}>
				Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde,
				verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung
				entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
				Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
				zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer-
				oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung
				nach Fortfall dieser Gründe.
			</Text>
			<Text style={s.h2}>
				Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website
			</Text>
			<Text style={s.body}>
				Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre
				personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit.
				a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im
				Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in
				Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
				DSGVO.{" "}
			</Text>
			<Text style={s.body}>
				Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr
				Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
				zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
				Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
				erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
				Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen
				Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
				Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs.
				1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird
				in den folgenden Absätzen dieser Datenschutzerklärung informiert.
			</Text>
			<Text style={s.h2}>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten </Text>
			<Text style={s.body}>
				Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
				datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre
				personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir
				weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau
				garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet,
				personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
				hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass
				US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
				Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
				Verarbeitungstätigkeiten keinen Einfluss.{" "}
			</Text>
			<Text style={s.h2}>Widerruf Ihrer Einwilligung zur Datenverarbeitung </Text>
			<Text style={s.body}>
				Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
				können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis
				zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. Widerspruchsrecht
				gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
			</Text>
			<Text style={s.body}>
				WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT,
				HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
				GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
				FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
				DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
				WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
				VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
				NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG
				DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH
				ART. 21 ABS. 1 DSGVO). WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
				BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
				BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT
				AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
				WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
				DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).{" "}
			</Text>
			<Text style={s.h2}>Beschwerderecht bei der zuständigen Aufsichtsbehörde</Text>
			<Text style={s.body}>
				Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
				Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
				Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
				unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.{" "}
			</Text>
			<Text style={s.h2}>Recht auf Datenübertragbarkeit </Text>
			<Text style={s.body}>
				Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
				Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
				maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
				an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
				ist.{" "}
			</Text>
			<Text style={s.h2}>Auskunft, Löschung und Berichtigung </Text>
			<Text style={s.body}>
				Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
				unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
				Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
				Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
				können Sie sich jederzeit an uns wenden.{" "}
			</Text>
			<Text style={s.h2}>Recht auf Einschränkung der Verarbeitung </Text>
			<Text style={s.body}>
				Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
				verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der
				Verarbeitung besteht in folgenden Fällen:
				<ul>
					<li>
						Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
						benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben
						Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
						verlangen.
					</li>
					<li>
						Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht,
						können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.{" "}
					</li>
					<li>
						Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
						Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht,
						statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
						verlangen.{" "}
					</li>
					<li>
						Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
						zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht,
						wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung
						Ihrer personenbezogenen Daten zu verlangen.{" "}
					</li>
				</ul>
			</Text>
			<Text style={s.body}>
				Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese
				Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
				Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
				einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen
				öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
			</Text>
			<Text style={s.h2}>SSL- bzw. TLS-Verschlüsselung </Text>
			<Text style={s.body}>
				Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
				Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
				senden, eine SSL- bzw. TLS- Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
				daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem
				Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist,
				können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.{" "}
			</Text>
			<Text style={s.h1}>4. Datenerfassung auf dieser Website </Text>
			<Text style={s.h2}>Server-Log-Dateien </Text>
			<Text style={s.body}>
				Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
				Server-Log- Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
				<ul>
					<li>Browsertyp und Browserversion</li>
					<li>verwendetes Betriebssystem </li>
					<li>Referrer URL Hostname des zugreifenden Rechners </li>
					<li>Uhrzeit der Serveranfrage </li>
					<li>IP-Adresse</li>
				</ul>
			</Text>
			<Text style={s.body}>
				Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Die
				Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
				Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung
				und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.
			</Text>
			<Text style={s.h2}>Anfrage per E-Mail, Telefon oder Telefax </Text>
			<Text style={s.body}>
				Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive
				aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der
				Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht
				ohne Ihre Einwilligung weiter. {"\n"}Die Verarbeitung dieser Daten erfolgt auf Grundlage von
				Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
				zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen
				übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
				Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
				Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
				jederzeit widerrufbar.{" "}
			</Text>
			<Text style={s.body}>
				Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns
				zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
				Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
				Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
				unberührt.{" "}
			</Text>
			<Text style={s.h1}>5. Plugins und Tools</Text>
			<Text style={s.h2}>Vimeo </Text>
			<Text style={s.body}>
				Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West
				18th Street, New York, New York 10011, USA. Wenn Sie eine unserer mit einem Vimeo-Video
				ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt.
				Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem
				erlangt Vimeo Ihre IP-Adresse. Dies gilt auch dann, wenn Sie nicht bei Vimeo eingeloggt sind
				oder keinen Account bei Vimeo besitzen. Die von Vimeo erfassten Informationen werden an den
				Vimeo-Server in den USA übermittelt. Wenn Sie in Ihrem Vimeo-Account eingeloggt sind,
				ermöglichen Sie Vimeo, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies
				können Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account ausloggen.{" "}
			</Text>
			<Text style={s.body}>
				Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies bzw. vergleichbare
				Wiedererkennungstechnologien (z. B. Device-Fingerprinting).
			</Text>
			<Text style={s.body}>
				Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer
				Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f
				DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
				ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit
				die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
				des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist
				jederzeit widerrufbar.{" "}
			</Text>
			<Text style={s.body}>
				Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
				sowie nach Aussage von Vimeo auf „berechtigte Geschäftsinteressen“ gestützt. Details finden
				Sie hier: https://vimeo.com/privacy. Weitere Informationen zum Umgang mit Nutzerdaten finden
				Sie in der Datenschutzerklärung von Vimeo unter: https://vimeo.com/privacy.
			</Text>
			<Text style={s.body}>
				Quelle:{"\n"}
				https://www.e-recht24.de
			</Text>
		</View>
	</View>;
}
